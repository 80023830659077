import React, { useState, useEffect } from 'react';
import GeneralOptions from '../../GeneralOptions/GeneralOptions';
import axios from 'axios';
import Config from '../../Bot/Config';
import {encryptObjectData,decryptObjectData} from '../../../Resources/Consumer/Utilities';

const PetBreedOptions = (props) => 
{
  const [options, setOptions] = useState([]);
  useEffect(() => 
  {
    const apiUrlValue=Config.state.apiUrl;
  const tokenValue=Config.state.token;
  const objData = 
  {
    'requestType': 'PetBreeds',
    'petType':props.petType,
    'token':tokenValue,
  };
  const encData=encryptObjectData(objData);
  const encObjDataToApi=
  {
    'encrypted':encData,
  };
  axios.post(apiUrlValue, encObjDataToApi,{timeout:10000}).then((encResponse) => 
  {
    let response=null;
    if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
    {
      response=decryptObjectData(encResponse.data.encrypted);
      if (response !== null && ('PetBreeds' in response))
      {
        setOptions(response.PetBreeds);
      }
      else
      {
        setOptions(['please refresh the page and try again!']);
      }
    }
    else
    {
        setOptions(['please refresh the page and try again!']);
    }
  }).catch((error) => 
  {
      if(error.response||error.request)
      {
        setOptions(['please check the internet and try again!']);
      }
  });
  }, []);
  const breed_options = [];
  if(options.length>0 && (options[0]).includes('try again'))
  {
    const dict = {}
    dict['name'] = options[0]
    dict['id'] = 0
    dict['handler'] = props.actionProvider.handlePetBreed
    breed_options.push(dict)
  }
  else
  {
    for (const [index, value] of options.entries()) 
    {
      const dict = {}
      dict['name'] = value
      dict['id'] = index
      dict['handler'] = props.actionProvider.handlePetBreed
      breed_options.push(dict)
    }
  }
  return <GeneralOptions options={breed_options} {...props} />;
};

export default PetBreedOptions;