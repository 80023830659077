import React from 'react';
import DogIcon from '../../../Resources/Consumer/DogTypeGif.gif';
import CatIcon from '../../../Resources/Consumer/CatTypeGif.gif';
import './PetTypeOptions.css';
const PetTypeOptions = (props) => 
{
  const options = [
    {
      path: DogIcon,
      handler: props.actionProvider.handlePetType,
      type: 'Dog',
      id: 1,
    },
    {
      path: CatIcon,
      handler: props.actionProvider.handlePetType,
      type: 'Cat',
      id: 2,
    }
  ];
  return (
    <div className='type-options-container'>
    {
      options.map(option => 
      {
        return (
          <div
            className='type-option-item'
            onClick={() => { option.handler(option.type, props) }}
            key={option.id}
          >
            <img style={{ width: '90%',height:'90%'}} src={option.path} />
          </div>
        );
      })
    }
    </div>
    );
};

export default PetTypeOptions;