import React, { Component } from "react";
import './Retailer.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import moment from 'moment';
import { ConditionallyRender } from "react-util-kit";
import {encryptObjectData,decryptObjectData} from '../../Resources/Consumer/Utilities';
import Config from '../Bot/Config'

class Retailer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toUpdate: true,
      selectedOption: "daily",
      isGoClicked: false,
      startDate: new Date(),
      endDate: new Date(),
      scans: "0",
      purchaseValue: "0",
      parentsPurchased: "0",
      moneyEarned: "0",
    }
  }
  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
      isGoClicked: false,
      toUpdate: true,
    })
  };
  handleClick = () => {
    this.setState({
      isGoClicked: true,
      toUpdate: true,
    })
  };
  componentWillMount() {
    const apiUrlValue =Config.state.apiUrlRetailer ;
    const tokenValue=Config.state.token;
    const date = new Date();
    date.setDate(date.getDate() - 1);
    const yest = moment(date).format('YYYY-MM-DD');

    const objData=
    { 
      "retailerId": this.props.retailerId, 
      "startdate": yest, 
      "enddate": yest,
      "token":tokenValue, 
    }

    const encData=encryptObjectData(objData);
    const encObjDataToApi=
    {
      'encrypted':encData,
    };
   
    axios.post(apiUrlValue,encObjDataToApi).then((encResponse) => {
     
      let response=null;
      if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)    
      {
        response=decryptObjectData(encResponse.data.encrypted);
        this.setState({
            scans: response.NumofScans,
            purchaseValue: response.ProductPurchaseValue,
            parentsPurchased: response.PetParentPurchased,
            moneyEarned: response.MoneyEarned,
            isGoClicked: false,
          })
      }
    });
  }
  componentDidUpdate() {
    if (this.state.selectedOption === "daily" && this.state.toUpdate) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      const yest = moment(date).format('YYYY-MM-DD');
      const apiUrlValue =Config.state.apiUrlRetailer ;
      const tokenValue=Config.state.token;
      const objData=
      { 
        "retailerId": this.props.retailerId, 
        "startdate": yest, 
        "enddate": yest,
        "token":tokenValue, 
      };
      const encData=encryptObjectData(objData);
      const encObjDataToApi=
      {
        'encrypted':encData,
      };
      axios.post(apiUrlValue,encObjDataToApi ).then((encResponse) => {
        let response=null;
      if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
      {
        response=decryptObjectData(encResponse.data.encrypted);
        this.setState({
            scans: response.NumofScans,
            purchaseValue: response.ProductPurchaseValue,
            parentsPurchased: response.PetParentPurchased,
            moneyEarned: response.MoneyEarned,
            toUpdate: false,
            isGoClicked: false,
          })
      }
      });

    }
    else if (this.state.selectedOption === "monthly" && this.state.toUpdate) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      const yest = moment(date).format('YYYY-MM-DD');
      const yestMonth = moment().startOf('month').format('YYYY-MM-DD');
      const apiUrlValue =Config.state.apiUrlRetailer ;
      const tokenValue=Config.state.token;
      const objData=
      { 
        "retailerId": this.props.retailerId, 
        "startdate": yestMonth, 
        "enddate": yest,
        "token":tokenValue, 
      }
      const encData=encryptObjectData(objData);
      const encObjDataToApi=
      {
        'encrypted':encData,
      };
      axios.post(apiUrlValue,encObjDataToApi).then((encResponse) => {
        
        let response=null;
      if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
      {
        response=decryptObjectData(encResponse.data.encrypted);
        this.setState({
            scans: response.NumofScans,
            purchaseValue: response.ProductPurchaseValue,
            parentsPurchased: response.PetParentPurchased,
            moneyEarned: response.MoneyEarned,
            toUpdate: false,
            isGoClicked: false,
          })
      }
      });
    }
    else if (this.state.selectedOption === "range" && this.state.isGoClicked && this.state.toUpdate) {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      const apiUrlValue =Config.state.apiUrlRetailer ;
      const tokenValue=Config.state.token;
      const objData=
      { 
        "retailerId": this.props.retailerId, 
        "startdate": moment(this.state.startDate).format('YYYY-MM-DD'), 
        "enddate": moment(this.state.endDate).format('YYYY-MM-DD'),
        "token":tokenValue,  
      }
      const encData=encryptObjectData(objData);
      const encObjDataToApi=
      {
        'encrypted':encData,
      };
      axios.post(apiUrlValue,encObjDataToApi).then((encResponse) => {
        let response=null;
      if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
      {
        response=decryptObjectData(encResponse.data.encrypted);
        this.setState({
            scans: response.NumofScans,
            purchaseValue: response.ProductPurchaseValue,
            parentsPurchased: response.PetParentPurchased,
            moneyEarned: response.MoneyEarned,
            toUpdate: false,
            isGoClicked: false,
          })
      }
      });
    }
  }
  render() {
    return (
      <Container fluid style={{ height: '100%', padding: '1%', margin: '0', }}>
        <div style={{ height: '15%', backgroundColor: 'white' }}>
          <Row style={{ backgroundColor: 'white', width: '100%', textAlign: 'center', height: '60%', padding: '1%', margin: '0' }} >
            <Col style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0', margin: '0' }} className="radio">
              <label style={{ fontWeight: '700' }}>
                <input type="radio" value="daily" name="time" checked={this.state.selectedOption === "daily"} onChange={this.handleOptionChange} style={{ marginRight: '5px' }} />
                  Daily
                </label>
            </Col>
            <Col style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0', margin: '0' }} className="radio">
              <label style={{ fontWeight: '700' }} >
                <input type="radio" value="monthly" name="time" checked={this.state.selectedOption === "monthly"} onChange={this.handleOptionChange} style={{ marginRight: '5px' }} />
                   Monthly
                </label>
            </Col>
            <Col style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0', margin: '0' }} className="radio">
              <label style={{ fontWeight: '700' }} >
                <input type="radio" value="range" name="time" checked={this.state.selectedOption === "range"} onChange={this.handleOptionChange} style={{ marginRight: '5px' }} />
                  Date Range
                </label>
            </Col>
          </Row>
          <ConditionallyRender            
            ifTrue={this.state.selectedOption === "range"}
            show={
              <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-evenly', backgroundColor: 'white', textAlign: 'center', width: '100%', height: '40%', padding: '0', margin: '0' }} >
                <div style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'darkblue', display: 'flex', alignItems: 'center', padding: '0', margin: '0' }} >
                  <i className="calendar-icon"></i>
                  <DatePicker className="dp1" selected={this.state.startDate} onChange={date => this.setState({ startDate: date })} dateFormat="yyyy-MM-dd" maxDate={new Date()} />
                </div>
                <div style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'darkblue', display: 'flex', alignItems: 'center', padding: '0', margin: '0' }} >
                  <i className="calendar-icon"></i>
                  <DatePicker className="dp1" selected={this.state.endDate} onChange={date => this.setState({ endDate: date })} dateFormat="yyyy-MM-dd" maxDate={new Date()} />
                </div>
                <Button style={{ height: '30px', width: '30px', textAlign: 'center', backgroundColor: 'darkblue', borderColor: 'darkblue', margin: '0', padding: '0' }} variant="primary" onClick={this.handleClick}>Go </Button>
              </div>
            } />
        </div>
        <div style={{ height: '85%', }}>
          <Row style={{ width: '100%', height: '50%', padding: '1%', margin: '0px' }}>
            <Col style={{ backgroundColor: 'white', padding: '0', margin: '1%' }}>
              <div className="one" style={{ height: '80%' }}>
              </div>
              <div style={{ marginLeft: 'auto', backgroundColor: 'lightsteelblue', height: '10%', width: 'fit-content' }}>
                <p style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: '700', textAlign: 'center' }}>{this.state.scans}</p>
              </div>
              <div style={{ backgroundColor: 'darkblue', height: '10%' }}>
                <p style={{ fontSize: '80%', textAlign: 'center', color: 'white', fontWeight: '700', margin: '0' }}>Number of Scans made</p>
              </div>
            </Col>
            <Col style={{ backgroundColor: 'white', padding: '0', margin: '1%' }}>
              <div className="two" style={{ height: '80%' }}>
              </div>
              <div style={{ marginLeft: 'auto', backgroundColor: 'lightsteelblue', height: '10%', width: 'fit-content' }}>
                <p style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: '700', textAlign: 'center' }}>{this.state.purchaseValue}</p>
              </div>
              <div style={{ backgroundColor: 'darkblue', height: '10%' }}>
                <p style={{ fontSize: '80%', textAlign: 'center', color: 'white', fontWeight: '700', margin: '0' }}>Product Purchase value</p>
              </div>
            </Col>

          </Row>
          <Row style={{ width: '100%', height: '50%', padding: '1%', margin: '0px' }}>
            <Col style={{ backgroundColor: 'white', padding: '0', margin: '1%' }}>
              <div className="three" style={{ height: '80%' }}>
              </div>
              <div style={{ marginLeft: 'auto', backgroundColor: 'lightsteelblue', height: '10%', width: 'fit-content' }}>
                <p style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: '700', textAlign: 'center' }}>{this.state.parentsPurchased}</p>
              </div>
              <div style={{ backgroundColor: 'darkblue', height: '10%' }}>
                <p style={{ fontSize: '80%', textAlign: 'center', color: 'white', fontWeight: '700', margin: '0' }}>Pet Parents Purchased</p>
              </div>
            </Col>
            <Col style={{ backgroundColor: 'white', padding: '0', margin: '1%' }}>
              <div className="four" style={{ height: '80%' }}>
              </div>
              <div style={{ marginLeft: 'auto', backgroundColor: 'lightsteelblue', height: '10%', width: 'fit-content' }}>
                <p style={{ paddingLeft: '5px', paddingRight: '5px', fontWeight: '700', textAlign: 'center' }} >{this.state.moneyEarned}</p>
              </div>
              <div style={{ backgroundColor: 'darkblue', height: '10%' }}>
                <p style={{ fontSize: '80%', textAlign: 'center', color: 'white', fontWeight: '700', margin: '0' }}>Rewards</p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
};
export default Retailer;