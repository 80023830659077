import {isInteger} from '../../Resources/Consumer/Utilities';

class MessageParser 
{
    constructor(actionProvider, state) 
    {
        this.actionProvider = actionProvider;
        this.state = state;
    }
    parse(name) 
    {
        if (this.state.proceed)         
        {           
            if (this.state.isReturningUser)         
            {       
                if (this.state.mobileNumber === "" && this.state.isMobileNumberRetrive)         //if this is true the user is entering mobilenumber and respective functions are invoked
                {     
                    // console.log(name);
                    // console.log(name.length);
                    // console.log(isInteger(name));  
                    if(name.length===10&&isInteger(name))
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                        inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handleMobileNumber(name);
                    }
                    else
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                        inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handleMobileNumberError(name);
                    }
                }
                if (this.state.isPetNameUpdate)     
                {       
                    if(name.length<=20&&name.length>=1)
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                         inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handlePetNameUpdate(name);
                    }    
                    else
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                        inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handlePetNameError(name);
                    }
                }
            }
            else 
            {
                if (this.state.petName === "") 
                {
                    if(name.length<=20&&name.length>=1)
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                        inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handlePetName(name);
                    }     
                    else
                    {
                        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
                        inputBoxElement.style.visibility='hidden';
                        return this.actionProvider.handlePetNameError(name);
                    }
                }
            }
        }
    }
}
export default MessageParser;