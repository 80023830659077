import React, { Component } from 'react';
import Chatbot, { createChatBotMessage } from 'react-chatbot-kit';
import Config from './Config';
import MessageParser from './MessageParser';
import ActionProvider from './ActionProvider';
import 'bootstrap/dist/css/bootstrap.min.css';
import CookieConsent from 'react-cookie-consent';
import TnC from '../TnC/TnC';
import './Bot.css';

class Bot extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state =
        {
            modalOpen: false,
        }
    }

    settingChatBotData()
    {
        const data = this.props.userData[0];
        const cookieValue = this.props.userData[1];
        const retailerId = this.props.retailerId;
        if (data.UserCheck === 'True')                              //checking for UserCheck to initialize starting messages in chatbot
        {
            Config.state.isReturningUser = true;
            Config.state.petName = data.UserData.petName;
            Config.state.petType = data.UserData.petType;
            Config.state.petBreed = data.UserData.petBreed;
            Config.state.petAge = data.UserData.petAge;
            Config.state.mobileNumber = data.UserData.mobileNumber;
            console.log(data.UserData.mobileNumber)
            Config.state.proceed = true;
            Config.initialMessages = 
            [
                createChatBotMessage(`Hello PetParent !!`),
                createChatBotMessage
                (   
                    `Welcome back to Mars PetCare.We're thrilled to see you again!`,
                    {
                        delay: 2000
                    }
                ),
                createChatBotMessage
                (
                    `We hope ${Config.state.petName} is doing great!!`,
                    {
                        delay: 4000
                    }
                ),
                createChatBotMessage
                (
                    `Would you like to see ${Config.state.petName}'s profile?`,
                    {
                        delay: 6000,
                        widget: 'viewPetProfileOptions',
                        loading: true,
                        terminateLoading: true,
                    }
                ),
            ];
        }
        else if (data.UserCheck === 'False')
        {
            Config.state.proceed = true;
            Config.initialMessages = 
            [
                createChatBotMessage(`Welcome to Mars Petcare. Hi I'm Maria.`),
                createChatBotMessage
                (
                    `Are you ...`,
                    {
                    delay:2000,
                    widget: 'petParentTypeOptions',
                    loading: true,
                    terminateLoading: true,
                    }
                )
            ];
        }
        else if(data.UserCheck === 'Retry')
        {
            Config.initialMessages = 
            [
                createChatBotMessage(`Please try again after some time`),
            ];
        }
        Config.state.cookieValue = cookieValue;
        Config.state.retailerId = retailerId;
    }

    handleModalOpen = () => 
    {
        this.setState((prevState) => 
        {
            return {
                modalOpen: !prevState.modalOpen
            }
        })
    }
    componentDidMount=()=>
    {
        const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
        //console.log(inputBoxElement)
        inputBoxElement.style.visibility='hidden';
    }
    render()
    {
        this.settingChatBotData();
        if(Config.initialMessages!==undefined)
        {
            return (
                <div>
                    <CookieConsent
                        style={{ background: '#ccc', color: 'black', fontSize: '12px', textAlign: 'center', display: 'unset' }}
                        buttonStyle={{ background: 'red', color: 'white', fontStyle: 'bold', borderRadius: '3px', }}
                        cookieName='ChatbotConsentCookie'
                        cookieValue={Config.state.cookieValue}
                        overlay
                        buttonText='ALLOW'
                        location='bottom'>
                        We use cookies to give you the best online experience. By clicking ALLOW you agree to our use of cookies in accordance with our cookie policy and <a href="#" style={{ color: 'red' }} onClick={this.handleModalOpen}>Terms and Conditions</a>.
                    </CookieConsent>
                    <TnC active={this.state.modalOpen} onClose={this.handleModalOpen} />
                    <div className='Bot'>
                        <Chatbot
                            config={Config}
                            messageParser={MessageParser}
                            actionProvider={ActionProvider}
                        />
                    </div>
                </div>
            );
        }
        else
        {
            return null;
        }
    }
}
export default Bot;