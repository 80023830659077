import React from 'react';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../UserAvatar/UserAvatar';
import PetParentTypeOptions from '../Widgets/PetParentTypeOptions/PetParentTypeOptions';
import PetTypeOptions from '../Widgets/PetTypeOptions/PetTypeOptions';
import PetBreedOptions from '../Widgets/PetBreedOptions/PetBreedOptions';
import PetAgeOptions from '../Widgets/PetAgeOptions/PetAgeOptions';
import PetFoodRecommendationCard from '../Widgets/PetFoodRecommendationCard/PetFoodRecommendationCard';
import PetProfileTable from '../Widgets/PetProfileTable/PetProfileTable';
import UpdatePetProfileOptions from '../Widgets/UpdatePetProfileOptions/UpdatePetProfileOptions';
import ViewPetProfileOptions from '../Widgets/ViewPetProfileOptions/ViewPetProfileOptions';
const Config =
{
    lang: 'en',
    state:
    {
        apiUrlRetailer:process.env.REACT_APP_API_URL_RETAILER,
        apiUrl:process.env.REACT_APP_API_URL,
        key:process.env.REACT_APP_KEY,
        token:process.env.REACT_APP_TOKEN,
        redirection:process.env.REACT_APP_REDIRECTION,
        petName: '',
        petType: '',
        petBreed: '',
        petAge: '',
        cookieValue: '',
        retailerId: '',
        proceed: false,
        isReturningUser: false,
        isPetNameUpdate: false,
        isMobileNumberRetrive: false,
        mobileNumber: '',
        didUpdate: false,
        updateEnd: false,
        dogMythsLink:process.env.REACT_APP_DOG_MYTHS_LINK,
        catMythsLink:process.env.REACT_APP_CAT_MYTHS_LINK,
        dogFoodAvoidLink:process.env.REACT_APP_DOG_FOOD_AVOID_LINK,
        catFoodAvoidLink:process.env.REACT_APP_CAT_FOOD_AVOID_LINK,
    },
    customComponents:
    {
        botAvatar: (props) => <BotAvatar {...props} />,
        userAvatar: (props) => <UserAvatar{...props} />,
    },
    widgets: 
  [
    {
      widgetName: 'petParentTypeOptions',
      widgetFunc: (props) => <PetParentTypeOptions {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'petBreed',
        'petAge',
        'cookieValue',
        'retailerId',
        'proceed',
        'isReturningUser',
        'isPetNameUpdate',
        'isMobileNumberRetrive', 
        'mobileNumber',
        'didUpdate',
        'updateEnd',
      ]
    },
    {
      widgetName: 'petTypeOptions',
      widgetFunc: (props) => <PetTypeOptions {...props} />,
      mapStateToProps:
        [
          'petName',
          'isReturningUser',
        ]
    },
    {
      widgetName: 'petBreedOptions',
      widgetFunc: (props) => <PetBreedOptions {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'isReturningUser',
      ]
    },
    {
      widgetName: 'petAgeOptions',
      widgetFunc: (props) => <PetAgeOptions {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'petBreed',
        'isReturningUser',
      ],
    },
    {
      widgetName: 'petFoodRecommendationCard',
      widgetFunc: (props) => <PetFoodRecommendationCard {...props} />,
      mapStateToProps:
      [
        'petType',
        'petAge',
        'cookieValue',
        'retailerId',
      ],
    },
    {
      widgetName: 'petProfileTable',
      widgetFunc: (props) => <PetProfileTable {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'petBreed',
        'petAge',
        'mobileNumber',
      ],
    },
    {
      widgetName: 'updatePetProfileOptions',
      widgetFunc: (props) => <UpdatePetProfileOptions {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'petBreed',
        'petAge',
        'cookieValue',
        'retailerId',
        'didUpdate',
      ],
    },
    {
      widgetName: 'viewPetProfileOptions',
      widgetFunc: (props) => <ViewPetProfileOptions {...props} />,
      mapStateToProps:
      [
        'petName',
        'petType',
        'petBreed',
        'petAge',
        'cookieValue',
        'retailerId',
        'isReturningUser',
        'updateEnd',
      ],
    },
  ],
};

export default Config;