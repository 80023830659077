import Config from '../../Components/Bot/Config';

export const encryptObjectData=(objData)=>
{
    const cryptoJs=require('crypto-js');
    const keyValue=Config.state.key;
    const objDataToStrData=JSON.stringify(objData);
    const encData=cryptoJs.AES.encrypt(objDataToStrData,keyValue).toString();
    return encData;
}

export const decryptObjectData=(objData)=>
{
    const cryptoJs=require('crypto-js');
    const keyValue=Config.state.key;
    const decData=JSON.parse((cryptoJs.AES.decrypt(objData,keyValue)).toString(cryptoJs.enc.Utf8));
    return decData;
}

export const encryptStringData=(strData)=>
{
    const cryptoJs=require('crypto-js');
    const keyValue=Config.state.key;
    const encData=cryptoJs.AES.encrypt(strData,keyValue).toString();
    return encData;
}

export const decryptStringData=(strData)=>
{
    const cryptoJs=require('crypto-js');
    const keyValue=Config.state.key;
    const decData=cryptoJs.AES.decrypt(strData,keyValue).toString(cryptoJs.enc.Utf8);
    return decData;
}
export const redirectionLinkGeneration=(argRetailerId,argCookieValue,argPetType,argPetAge)=>
{ 
    const cookieValue=argCookieValue;
    const retailerId=argRetailerId;
    const petType=argPetType;
    const petAge=argPetAge;
    const encCookieValue=encryptStringData(cookieValue);
    const encRetailerId=encryptStringData(retailerId);
    const encPetType=encryptStringData(petType);
    const encPetAge=encryptStringData(petAge);
    const redirection=Config.state.redirection;
    const redirectionLink=`${redirection}?utm_medium=qrcode&utm_affiliate_id=${encRetailerId}&utm_cookie_id=${encCookieValue}&affiliate_type=QRCODE&pet_type=${encPetType}&life_age=${encPetAge}`;
    return redirectionLink;
}
export const isInteger=(value)=>
{
    // let x;
    // return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
    return !isNaN(parseFloat(value)) && !isNaN(value - 0);
}