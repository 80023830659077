import React, { Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import Bot from './Components/Bot/Bot';
import {encryptObjectData,decryptObjectData} from './Resources/Consumer/Utilities.jsx';
import Config from './Components/Bot/Config';
import { Cookies } from 'react-cookie-consent';
import axios from 'axios';
import Retailer from './Components/Retailer/Retailer'

const ConsumerInvocFunc = () => 
{
    const [userData, setUserData] = useState(null);
    const tokenValue=Config.state.token;
    const apiUrlValue=Config.state.apiUrl;
    let query = new URLSearchParams(useLocation().search);
    const retailerId = query.get('retailer_id');
    useEffect(() => 
    {
        let cookieValue = Cookies.get('ChatbotConsentCookie');
        if (cookieValue === undefined)
        {
            cookieValue = Math.random().toString(36).slice(2) + Date.now();
            setUserData([{ 'UserCheck': 'False' }, cookieValue]);
        }
        else
        {
            
            const objData = 
            {
                'requestType': 'UserCheck',
                'cookieValue': cookieValue,
                'token':tokenValue,
            };
            const encData=encryptObjectData(objData);
            const encObjDataToApi=
            {
                'encrypted':encData,
            };
            axios.post(apiUrlValue, encObjDataToApi).then((encResponse) =>
            {
                let response=null;
                if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
                {
                    response=decryptObjectData(encResponse.data.encrypted);
                    
                    if (response !== null && ('UserCheck' in response))
                    {
                        if (response.UserCheck === 'Retry')
                        {
                            setTimeout(() =>
                            {
                                axios.post(apiUrlValue, encObjDataToApi).then((encResponse) =>
                                {
                                    if(encResponse!==null &&'data' in encResponse &&'encrypted' in encResponse.data)
                                    {
                                        response=decryptObjectData(encResponse.data.encrypted);
                                        if (response !== null && 'UserCheck' in response)
                                        {
                                            setUserData([response, cookieValue]);
                                        }
                                        else
                                        {
                                            setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                                        }
                                    }
                                    else
                                    {
                                        setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                                    }
                                }).catch((error)=>{
                                    if(error.response||error.request)
                                    {
                                        setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                                    }
                                });
                            },500);
                        }
                        else
                        {
                            setUserData([response, cookieValue]);
                        }
                    }
                    else
                    {
                        setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                    }
                }
                else
                {
                    setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                }
            }).catch((error)=>{
                if(error.response||error.request)
                {
                    setUserData([{ 'UserCheck': 'Retry' }, cookieValue]);
                }
            });
        }
    },[]);
    if(retailerId !== '' && retailerId !== null && userData !== null)
    {
        return(
            <Bot userData={userData} retailerId={retailerId} />
        );
    }
    else
    {
        return null;
    }
}
const RetailerInvocFunc = () => 
{
    let query = new URLSearchParams(useLocation().search);
    const retailerId = query.get('retailer_id');
    if(retailerId !== '' && retailerId !== null)
    {
        return (
            <Retailer retailerId={retailerId} />
        );
    }
    else
    {
        return null;
    }
}
class App extends Component
{
  render()
  {
    const Route=require('react-router-dom').Route;
    return (
      <Router>
            <div>
                <Route path='/consumer'>
                    <ConsumerInvocFunc />
                </Route>
                <Route path='/retailer'>
                    <RetailerInvocFunc />
                </Route>
            </div>
      </Router>
    );
  }
}

export default App;