import React from "react";
import GeneralOptions from '../../GeneralOptions/GeneralOptions';

const ViewPetProfileOptions = (props) => 
{
    const options = 
    [
        {
            name: "Yes",
            handler: props.actionProvider.handleViewPetProfile,
            id: 1
        },
        {
            name: "No",
            handler: props.actionProvider.handleViewPetProfile,
            id: 2
        }
    ];
    return <GeneralOptions options={options}{...props} />;
};

export default ViewPetProfileOptions;