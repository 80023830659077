import axios from 'axios';
import { Cookies } from 'react-cookie-consent';
import {encryptObjectData,decryptObjectData} from '../../Resources/Consumer/Utilities';
import Config from '../Bot/Config';

class ActionProvider
{
  constructor(createChatBotMessage, setStateFunc,createClientMessage)
  {
    this.createChatBotMessage = createChatBotMessage;
    this.setStateFunc = setStateFunc;
    this.createClientMessage = createClientMessage;
  }

  setPetName = (name) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        petName: name,
      }
    ));
  };

  //set function used for setting petNameUpdate

  setIsPetNameUpdate = (argIsPetNameUpdate) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        isPetNameUpdate: argIsPetNameUpdate,
      }
    ));
  };

  //set function used for setting petType

  setPetType = (type) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        petType: type,
      }
    ));
  };

  //set function used for setting petBreed

  setPetBreed = (breed) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        petBreed: breed,
      }
    ));
  };

  //set function used for setting cookieValue

  setCookieValue = (argCookieValue) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        cookieValue: argCookieValue,
      }
    ));
  };

  //set function used for setting petAge

  setPetAge = (age) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        petAge: age,
      }
    ));
  };

  //set function used for setting mobileNumber

  setMobileNumber = (argMobileNumber) => 
  {
    this.setStateFunc((state) => 
    (
      {                                                    //review this later
        ...state,
        mobileNumber: argMobileNumber,
      }
    ));
  };

  //set function used for setting proceed

  setProceed = (isProceed) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        proceed: isProceed,
      }
    ));
  };

  //set function used for setting mobileNumberRetrive

  setIsMobileNumberRetrive = (argIsMobileNumberRetrive) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        isMobileNumberRetrive: argIsMobileNumberRetrive,
      }
    ));
  };

  //set function used for setting isReturningUser

  setIsReturningUser = (argIsReturningUser) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        isReturningUser: argIsReturningUser,
      }
    ));
  };

  //set function used for setting didUpdate

  setDidUpdate = (argDidUpdate) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        didUpdate: argDidUpdate,
      }
    ));
  };

  //set function used for setting updateEnd

  setUpdateEnd = (argUpdateEnd) => 
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        updateEnd: argUpdateEnd,
      }
    ));
  };

  //this function used for showing petTypes

  showPetTypeOptions = (wait, name) => 
  {
    const chatMessage1 = this.createChatBotMessage
      (
        `Is ${name} Dog or Cat ?`, 
        {
          delay: wait,
        }
      );
    this.addMessageToState(chatMessage1);
    const chatMessage2 = this.createChatBotMessage
      (
        `Please proceed by selecting ${name}'s type`,
        {
          delay: wait + 2000,
          widget: 'petTypeOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage2);
  };

  //this function used for showing petBreeds

  showPetBreedOptions = (wait, name) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `Please select ${name}'s breed`,
        {
          delay: wait,
          widget: 'petBreedOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  //this function used for showing petAges

  showPetAgeOptions = (wait, name) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `How old is ${name} ?`,
        {
          delay: wait,
          widget: 'petAgeOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  //this function used for showing UpdateOptions

  showUpdatePetProfileOptions = (wait, petName) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `Would you like to update ${petName}'s profile ?`,
        {
          delay: wait,
          widget: 'updatePetProfileOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  //this function used for showing summary

  showPetProfileTable = (wait, petName) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `Here is ${petName}'s profile`,
        {
          delay: wait,
          widget: 'petProfileTable',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  showPetFoodRecommendationCard = (wait, petname) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `Here are the pet food variants that are recommended for ${petname}`,
        {
          delay: wait,
          widget: 'petFoodRecommendationCard',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  //this function used for showing summaryChooser

  showViewPetProfileOptions = (wait, petName) => 
  {
    const chatMessage = this.createChatBotMessage
      (
        `Would you like to see ${petName}'s profile?`,
        {
          delay: wait,
          widget: 'viewPetProfileOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  };

  //this function used for showing userSelector

  showPetParentTypeOptions=(wait)=>
  {
    const chatMessage = this.createChatBotMessage
      (
        `Are you ...`,
        {
          delay:wait,
          widget: 'petParentTypeOptions',
          loading: true,
          terminateLoading: true,
        }
      );
    this.addMessageToState(chatMessage);
  }

  showUserMessage=(Message,wait)=>
  {
    const userMessage=this.createClientMessage
      (
        Message,
        {
          delay:wait,
          loading:true,
          terminateLoading:true,
        }
      );
      this.addMessageToState(userMessage);
  }

  handlePetParentType = (type,props) =>     //done
  {
    if (type === 'First time User') 
    {
      this.showUserMessage('First time User',0);
      this.setIsReturningUser(false);
      const chatMessage = this.createChatBotMessage
        (
          `Please enter your Pet's name`,
          {
            delay:2000,
          }
        );
      this.addMessageToState(chatMessage);
      const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
      console.log(inputBoxElement);
      inputBoxElement.style.visibility='visible';
    }
    else if (type === 'Returning User') 
    {
      this.showUserMessage('Returning User',0);
      this.setIsReturningUser(true);
      this.setIsMobileNumberRetrive(true);
      const chatMessage = this.createChatBotMessage
        (
          `Please enter your Mobile number`,
          {
            delay:2000,
          }
        );
      this.addMessageToState(chatMessage);
      const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
      inputBoxElement.style.visibility='visible';
    }
  }

  setPetProfileData = (response) => 
  {
    if (response.UserCheck==='True') 
    {
      this.setPetName(response.UserData.petName);
      this.setPetType(response.UserData.petType);
      this.setPetBreed(response.UserData.petBreed);
      this.setPetAge(response.UserData.petAge);
      this.setMobileNumber(response.UserData.mobileNumber);
      this.setCookieValue(response.UserData.cookieValue);
      Cookies.set('ChatbotConsentCookie', response.UserData.cookieValue)
      this.showViewPetProfileOptions(0,response.UserData.petName);
    }
    else if(response.UserCheck==='False')
    {
      //console.log("response contains UserCheck as false so showing user types again");
      this.setIsReturningUser(false);
      const chatMessage1 = this.createChatBotMessage
        (
          `User NotFound!! Please TryAgain!!`
        );
      this.addMessageToState(chatMessage1);
      this.showPetParentTypeOptions(2000);
    }
    else if(response.UserCheck==='Retry')
    {
      //console.log("response contains UserCheck as retry so stopping chatbot giving replies");
      this.setProceed(false);
      const chatMessage1 = this.createChatBotMessage
        (
          `Please check internet and refresh the page!`,
        );
      this.addMessageToState(chatMessage1);
    }
  }

  handleMobileNumber = (argMobileNumber) => 
  {
    const apiUrlValue=Config.state.apiUrl;
    const tokenValue=Config.state.token;
    const objData = 
    {
      'requestType': 'UserCheck',
      'mobileNumber':argMobileNumber,
      'token':tokenValue,
    };
   
    const encData=encryptObjectData(objData);
    const encObjDataToApi=
    {
      'encrypted':encData,
    };
    axios.post(apiUrlValue, encObjDataToApi,{timeout:10000}).then((encResponse) => 
    {
      let response=null;
      if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
      {
        response=decryptObjectData(encResponse.data.encrypted);
        if (response !== null && ('UserCheck' in response))
        {
          // console.log("Mobile numb resp");
          // console.log(response);
          if (response.UserCheck === 'Retry') 
          {
            setTimeout(() => 
            {
              axios.post(apiUrlValue, encObjDataToApi,{timeout:10000}).then((encResponse) => 
              {
                let response=null;
                if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
                {
                  response=decryptObjectData(encResponse.data.encrypted);
                  if (response !== null && ('UserCheck' in response))
                  {
                    this.setPetProfileData(response)
                  }
                  else
                  {
                    this.setPetProfileData({'UserCheck':'Retry'});
                  }
                }
                else
                {
                  this.setPetProfileData({'UserCheck':'Retry'});
                }
              }).catch((error) => 
              {
                if(error.response||error.request)
                {
                  this.setPetProfileData({'UserCheck':'Retry'});
                }
              });
            }, 500);
          }
          else 
          {
            this.setPetProfileData(response)
          }
        }
        else
        {
          this.setPetProfileData({'UserCheck':'Retry'});
        }
      }
      else
      {
        this.setPetProfileData({'UserCheck':'Retry'});
      }
    }).catch((error) => 
    {
      if(error.response||error.request)
      {
        this.setPetProfileData({'UserCheck':'Retry'});
      }
    });
  };


  handlePetName = (name) => //done
  {
    this.setPetName(name);
    const chatMessage = this.createChatBotMessage
      (
        `We hope ${name} is as healthy,playful and active as ever!!`,
        {
          delay:2000,
        }
      );
    this.addMessageToState(chatMessage);
    this.showPetTypeOptions(4000, name);
  };

  handlePetType = (type,props) => 
  {
    this.showUserMessage(type,0);
    this.setPetType(type);
    if (props.isReturningUser) 
    {
      const chatMessage = this.createChatBotMessage
        (
          `${props.petName}'s type has been updated to '${type}'`,
          {
            delay:2000,
          }
        );
      this.addMessageToState(chatMessage);
      this.showPetBreedOptions(4000, props.petName);
    }
    else 
    {
      this.showPetBreedOptions(2000, props.petName);
    }
  };

  handlePetBreed = (breed,props) => //done
  {
    this.showUserMessage(breed,0);
    if((breed).includes('try again'))
    {
      this.setProceed(false);
    }
    else
    {
      this.setPetBreed(breed);
      if (props.isReturningUser) 
      {
        const chatMessage1 = this.createChatBotMessage
          (
            `${props.petName}'s breed has ben updated to '${breed}'`,
            {
              delay:2000,
            }
          );
        this.addMessageToState(chatMessage1);
        this.showPetAgeOptions(4000, props.petName);
      }
      else 
      {
        this.showPetAgeOptions(2000, props.petName);
      }
    }
  };

  handlePetAge = (age, props) => 
  {
    this.showUserMessage(age,0);
    if(age.includes('try again'))
    {
      this.setProceed(false);
    }
    else
    {
      this.setPetAge(age);
      if (props.isReturningUser) 
      {
        const chatMessage1 = this.createChatBotMessage
          (
            `Your Pet's age has been updated to '${age}'`,
            {
              delay:2000,
            }
          );
        this.addMessageToState(chatMessage1);
        this.showUpdatePetProfileOptions(4000, props.petName);
      }
      else 
      {
        this.showViewPetProfileOptions(2000, props.petName);
      }
    }
  };

  handleViewPetProfile = (choice,props) => 
  {
    const tokenValue=Config.state.token;
    const apiUrlValue = Config.state.apiUrl;
    if (choice === 'Yes') 
    {
      this.showUserMessage('Yes',0);
      this.showPetProfileTable(2000, props.petName);
    }
    if(choice==='No')
    {
      this.showUserMessage('No',0);
    }
    if (props.isReturningUser && !props.updateEnd) 
    {
      if(choice==='Yes')
      {
        this.showUpdatePetProfileOptions(4000, props.petName);
      }
      else if(choice==='No')
      {
        this.showUpdatePetProfileOptions(2000, props.petName);
      }
    }
    else 
    {
      const objData=
      {
        'requestType': 'UserData',
        'petName': props.petName,
        'petType': props.petType,
        'petAge': props.petAge,
        'petBreed': props.petBreed,
        'retailerId': props.retailerId,
        'cookieValue': props.cookieValue,
        'token':tokenValue,
      }
      const encData=encryptObjectData(objData);
      const encObjDataToApi=
      {
        'encrypted':encData,
      } 
      axios.post(apiUrlValue, encObjDataToApi,{timeout:15000}).then((encResponse) => 
      {
        let response=null;
        if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
        {
          response=decryptObjectData(encResponse.data.encrypted);
          if(response!==null&& 'Response' in response)
          {
            if(response.Response!==true)
            {
              setTimeout(() => 
              {
                axios.post(apiUrlValue, encObjDataToApi,{timeout:15000}).then((encResponse) => 
                {
                  let response=null;
                  if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
                  {
                    response=decryptObjectData(encResponse.data.encrypted);
                    if(response!==null&&'Response' in response)
                    {
                      if(response.Response!==true)
                      {
                        const chatMessage1 = this.createChatBotMessage
                        (
                          `please refresh the page try again`
                        );
                        this.addMessageToState(chatMessage1);
                        this.setProceed(false);
                      }
                      else
                      {
                        if(choice==='Yes')
                        {
                          this.showPetFoodRecommendationCard(4000, props.petName);
                        }
                        else if(choice==='No')
                        {
                          this.showPetFoodRecommendationCard(2000, props.petName);
                        }
                      }
                    }
                    else
                    {
                      const chatMessage1 = this.createChatBotMessage
                      (
                        `please refresh the page try again`
                      );
                      this.addMessageToState(chatMessage1);
                      this.setProceed(false);
                    }
                  }
                  else
                  {
                    const chatMessage1 = this.createChatBotMessage
                    (
                      `please refresh the page try again`
                    );
                    this.addMessageToState(chatMessage1);
                    this.setProceed(false);
                  } 
                }).catch((error) => 
                {
                  if (error.response||error.request) 
                  {
                    const chatMessage1 = this.createChatBotMessage
                    (
                      `please check internet and try again`
                    );
                    this.addMessageToState(chatMessage1);
                    this.setProceed(false);
                  }
                });
              }, 500);
            }
            else
            {
              if(choice==='Yes')
              {
                this.showPetFoodRecommendationCard(4000, props.petName);
              }
              else if(choice==='No')
              {
                this.showPetFoodRecommendationCard(2000, props.petName);
              }
            }
          }
          else
          {
            const chatMessage1 = this.createChatBotMessage
            (
              `please refresh the page try again`
            );
            this.addMessageToState(chatMessage1);
            this.setProceed(false);
          }
        }
        else
        {
          const chatMessage1 = this.createChatBotMessage
          (
            `please refresh the page try again`
          );
          this.addMessageToState(chatMessage1);
          this.setProceed(false);
        }
      }).catch((error) => 
      {
        if (error.response||error.request) 
        {
          const chatMessage1 = this.createChatBotMessage
          (
            `please check internet and try again`
          );
          this.addMessageToState(chatMessage1);
          this.setProceed(false);
        }
      });
    }
  };

  handleUpdatePetProfile = (name, props) => 
  {
    const tokenValue=Config.state.token;
    const apiUrlValue = Config.state.apiUrl;
    if (name === 'PetName') 
    {
      this.showUserMessage('PetName',0);
      this.setIsPetNameUpdate(true);
      this.setDidUpdate(true);
      const chatMessage = this.createChatBotMessage
        (
          `Could you please tell us the new name for your pet?`,
          {
            delay:2000,
          }
        );
      this.addMessageToState(chatMessage);
      const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
      inputBoxElement.style.visibility='visible';
    }
    else if (name === 'PetType') 
    {
      this.showUserMessage('PetType',0);
      this.setDidUpdate(true);
      this.showPetTypeOptions(2000, props.petName);
    }
    else if (name === 'PetBreed')
    {
      this.showUserMessage('PetBreed',0);
      this.setDidUpdate(true);
      this.showPetBreedOptions(2000, props.petName);
    }
    else if (name === 'PetAge') 
    {
      this.showUserMessage('PetAge',0);
      this.setDidUpdate(true);
      this.showPetAgeOptions(2000, props.petName);
    }
    else if (name === 'NO') 
    {
      this.showUserMessage('NO',0);
      if (props.didUpdate) 
      {
        this.setUpdateEnd(true);
        this.showViewPetProfileOptions(2000, props.petName);
      }
      else 
      {
        const objData=
        {
          'requestType': 'UserData',
          'petName': props.petName,
          'petType': props.petType,
          'petAge': props.petAge,
          'petBreed': props.petBreed,
          'retailerId': props.retailerId,
          'cookieValue': props.cookieValue,
          'token':tokenValue,
        }
        const encData=encryptObjectData(objData);
        const encObjDataToApi=
        {
          'encrypted':encData,
        } 
        axios.post(apiUrlValue, encObjDataToApi,{timeout:15000}).then((encResponse) => 
        {
          let response=null;
          if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
          {
            response=decryptObjectData(encResponse.data.encrypted);
            if(response!==null&& 'Response' in response)
            {
              if(response.Response!==true)
              {
                setTimeout(() => 
                {
                  axios.post(apiUrlValue, encObjDataToApi,{timeout:15000}).then((encResponse) => 
                  {
                    let response=null;
                    if(encResponse!==null && 'data' in encResponse && 'encrypted' in encResponse.data)
                    {
                      response=decryptObjectData(encResponse.data.encrypted);
                      if(response!==null&&'Response' in response)
                      {
                        if(response.Response!==true)
                        {
                          const chatMessage1 = this.createChatBotMessage
                          (
                            `please refresh the page try again`
                          );
                          this.addMessageToState(chatMessage1);
                          this.setProceed(false);
                        }
                        else
                        {
                          this.showPetFoodRecommendationCard(2000, props.petName);
                        }
                      }
                      else
                      {
                        const chatMessage1 = this.createChatBotMessage
                        (
                          `please refresh the page try again`
                        );
                        this.addMessageToState(chatMessage1);
                        this.setProceed(false);
                      }
                    }
                    else
                    {
                      const chatMessage1 = this.createChatBotMessage
                      (
                        `please refresh the page try again`
                      );
                      this.addMessageToState(chatMessage1);
                      this.setProceed(false);
                    }
                  }).catch((error) => 
                  {
                    if (error.response||error.request) 
                    {
                      const chatMessage1 = this.createChatBotMessage
                      (
                        `please check internet and try again`
                      );
                      this.addMessageToState(chatMessage1);
                      this.setProceed(false);
                    }
                  });
                }, 500);
              }
              else
              {
                this.showPetFoodRecommendationCard(2000, props.petName);
              }
            }
            else
            {
              const chatMessage1 = this.createChatBotMessage
              (
                `please refresh the page try again`
              );
              this.addMessageToState(chatMessage1);
              this.setProceed(false);
            }
          }
          else
          {
            const chatMessage1 = this.createChatBotMessage
            (
              `please refresh the page try again`
            );
            this.addMessageToState(chatMessage1);
            this.setProceed(false);
          }
        }).catch((error) => 
        {
          if (error.response||error.request) 
          {
            const chatMessage1 = this.createChatBotMessage
              (
                `please check internet and try again`
              );
              this.addMessageToState(chatMessage1);
              this.setProceed(false);
          }
        });
      }
    }
  };

  handlePetNameUpdate = (name) => 
  {
    this.setPetName(name);
    this.setIsPetNameUpdate(false);
    const chatMessage = this.createChatBotMessage
      (
        `Your Pet's name has been updated to '${name}'`,
        {
          delay:2000,
          loading: true,
        },
      );
    this.addMessageToState(chatMessage);
    this.showUpdatePetProfileOptions(2000, name);
  };
  handlePetNameError = (name) => //done
  {
    const chatMessage1 = this.createChatBotMessage
    (
      `Pet's name should not be more than 20 characters!`,
      {
        delay:2000,
      }
    );
    this.addMessageToState(chatMessage1);
    const chatMessage2 = this.createChatBotMessage
    (
      `Please Enter a valid Pet's name`,
      {
        delay:4000,
        loading: true,
        terminateLoading: true,
      }
    );
    this.addMessageToState(chatMessage2);
    const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    inputBoxElement.style.visibility='visible';
  }
  handleMobileNumberError = (name) => //done
  {
    const chatMessage1 = this.createChatBotMessage
    (
      `Mobile Number is NOT Valid!`,
      {
        delay:2000,
      }
    );
    this.addMessageToState(chatMessage1);
    const chatMessage2 = this.createChatBotMessage
    (
      `Please Enter a valid mobile number`,
      {
        delay:4000,
        loading: true,
        terminateLoading: true,
      }
    );
    this.addMessageToState(chatMessage2);
    const inputBoxElement=document.getElementsByClassName('react-chatbot-kit-chat-input-container')[0];
    inputBoxElement.style.visibility='visible';
  }
  handleExit = () => 
  {
    const botElement=document.getElementsByClassName('Bot')[0];
    alert('Thankyou for visiting Mars Petcare\nHave a Nice Day!');
    botElement.style.visibility='hidden';
  };

  addMessageToState = (message) => //done
  {
    this.setStateFunc((state) => 
    (
      {
        ...state,
        messages: [...state.messages, message],
      }
    ));
  };
};
export default ActionProvider;