import React from 'react';
import './GeneralOptions.css';

const GeneralOptions = (props) => 
{
  return (
    <div className='general-options'>
      <div className='general-options-container'>
        {
          props.options.map(option => 
          {
            return (
              <div
                className='general-option-item'
                onClick={() => { option.handler(option.name,props) }}
                key={option.id}
              >
                {option.name}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default GeneralOptions;