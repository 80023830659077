import React from "react";          //to import react
import { ReactComponent as DogIcon } from "../../Resources/Consumer/DogIcon.svg";          //to import svg icon as react-component
import { ReactComponent as CatIcon } from "../../Resources/Consumer/CatIcon.svg";
import { ReactComponent as UserIcon } from "../../Resources/Consumer/CatIcon.svg";
import './UserAvatar.css';          //css file location for BotAvatar
import Config from '../Bot/Config.js';

const UserAvatar = () => 
{
  const petTypeValue=Config.state.petType;
  if(petTypeValue==="Dog")
  {
    return (
      <div className="react-chatbot-kit-chat-user-avatar">
        <div className="react-chatbot-kit-chat-user-avatar-container">
          <DogIcon className="react-chatbot-kit-chat-user-avatar-icon" />
        </div>
      </div>
    );
  }
  else if(petTypeValue==="Cat")
  {
    return (
        <div className="react-chatbot-kit-chat-user-avatar">
          <div className="react-chatbot-kit-chat-user-avatar-container">
            <CatIcon className="react-chatbot-kit-chat-user-avatar-icon" />
          </div>
        </div>
    );
  }
  else
  {
    return (
        <div className="react-chatbot-kit-chat-user-avatar">
          <div className="react-chatbot-kit-chat-user-avatar-container">
            <UserIcon className="react-chatbot-kit-chat-user-avatar-icon" />
          </div>
        </div>
    );
  }
};

export default UserAvatar;