import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

class Tnc extends Component {
  render() {
    return (
      <Modal
        scrollable={true}
        show={this.props.active}
        size="lg"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered>

        <Modal.Header >
          <Modal.Title style={{ textAlign: 'center', width: '100%' }} >
            <h4>Terms and Conditions</h4>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ 'overflowY': 'auto', 'maxHeight': '90%' }}>
          <p>
            <b>IMPORTANT:</b>PLEASE READ THESE TERMS AND CONDITIONS BEFORE PARTICIPATING, “QR CODE PROGRAM”. THIS IS A LEGALLY BINDING AGREEMENT BETWEEN YOU (PET PARENT) AND MARS INTERNATIONAL INDIA PVT. LTD. (MARS INDIA) ESTABLISHING THE TERMS AND CONDITIONS UNDER WHICH YOU ARE ENTITLED TO PARTICIPATE IN THIS PROGRAM. BY PARTICIPATING IN THIS PROGRAM, YOU CONFIRM THAT YOU HAVE READ AND UNDERSTOOD, AND AGREE TO ABIDE BY, THESE TERMS AND CONDITIONS.<br />
            <b>Cookies Policy</b>: We use cookies to give you the best online experience. By clicking ALLOW you agree to our use of cookies in accordance with our cookie policy</p>
          <p>Eligibility:</p>
          <ul>
            <li>General Eligibility Requirements: -</li>
            <ul>
              <li>All persons residing in India are eligible to participate in QR Code- alternate ways of purchase</li>
              <li>Individuals above 18 years of age</li>
              <li>Only individuals are eligible to participate in the QR Code model of Purchase. Groups, organizations and company entries are not eligible</li>
            </ul>
            <li>The following are not eligible to participate in the QR Code Program</li>
            <ul>
              <li>Employees of MARS and its related corporations and their immediate family members</li>
              <li>Employees of any party which is directly involved in organizing, promoting or conducting the QR Code program</li>
            </ul>
            <li>QR Code model of Purchase specifics:</li>
            <ul>
              <li>In order to participate in QR Code Program, you must visit the Vet/ Pet clinic/Grooming Centre</li>
              <li>Pet Parent must provide complete details as per the required information to guide them to land in specific platform for purchase/know about Pets and Nutrition</li>
              <li>Once registered Pet details entered the Pet Parent will be directed to d2c platform for purchase/Technical bank or Exit norms</li>
            </ul>
            <li>Participation steps:</li>
            <ul>
              <li>Step 1: Visit the Pedigree pet store/ Vet/ Pet clinic/Grooming center</li>
              <li>Step 2: Scan the QR Code in the store, agree to T&C</li>
              <li>Step 3: Pet Parent will provide required information in the chatbot</li>
              <li>Step 4: System will guide for Proper food basis data provided or Pet parent can choose for Technical update or exit</li>
              <li>Step 5: To purchase Pet parent will land in d2c platform and order food of his choice/guided by system</li>
              <li>Step 6: Pet Parent receives communication on order details and shipment via message and e mail </li>
            </ul>
          </ul>
          <p>
            All entries become the property of MARS India. Personal details of the participants collected by MARS India may also be used for marketing purposes. However, such personal details will be dealt with in accordance with MARS India privacy policy http://www.pedigree.in/privacy-policy.html
            To the maximum extent permitted by law, MARS India and its parent company, affiliates, related corporations, subsidiaries, directors, officers, employees and agents accepts no responsibility whatsoever for any liabilities of any kind for any damage to any property that may occur as a consequence of your participation in Wer4pets Sampling Program.
            To the maximum extent permitted by law, you undertake to indemnify and keep MARS India and its parent company, affiliates, related corporations, subsidiaries, directors, officers, employees and agents harmless and indemnified against any loss, damage, claims, costs and expenses which may be incurred or suffered by any or each of them due to your breach of any of these Terms and Conditions and/or arising from your participation in Wer4pets Sampling Program.
            In the event of any dispute arising from, about or in relation to QR Code Program, or relating to the interpretation of these Terms and Conditions, the decision of MARS India on all such matters shall be final and binding on all parties.
            MARS India may modify these Terms and Conditions and/or withdraw or terminate QR Code Program at any stage without any liability towards anyone. Each of the provisions of these terms and conditions apply to the maximum extent permitted by applicable law. If a court holds any provision of these Terms and Conditions to be illegal, invalid or unenforceable, the rest of these Terms and Conditions will remain in effect and these Terms and Conditions will be amended to give effect to the eliminated provision to the maximum extent possible.
QR Code Program shall be governed by and interpreted in accordance with the laws of India and Courts in New Delhi shall have exclusive jurisdiction over all matters concerning the Wer4pets Sampling Program.</p>
          <br />
          <p>PRIVACY NOTICE</p>
          <br />
          <p>
            The personal data you provide to MARS India or its agents pursuant to QR Code Program, including without limitation your name, contact number, will be processed and are required to administer your participation in QR Code Program. MARS India may also use your personal data to communicate with you about its products and services, update you on new services and benefits, provide personalized promotional offers and allow you to participate in contests and surveys. If you have any complaints, comments or questions on this Privacy Notice, or wish to access or correct your personal data, or limit our processing of the same, please contact MARS India at pedigree.india@effem.com
        </p>
        </Modal.Body>

        <Modal.Footer>
          <Button style={{ backgroundColor: 'blue', borderColor: 'blue', fontSize: 'large' }} onClick={this.props.onClose}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Tnc;