import React from "react";      //to import react
import { ReactComponent as BotIcon } from "../../Resources/Consumer/DogIcon.svg";       //to import svg icon as react-component
import './BotAvatar.css';       //css file location for BotAvatar

const BotAvatar = () => 
{
    return (
        <div className="react-chatbot-kit-chat-bot-avatar">
            <div className="react-chatbot-kit-chat-bot-avatar-container">
                <BotIcon className="react-chatbot-kit-chat-bot-avatar-icon" />
            </div>
        </div>
    );
};

export default BotAvatar;