import React from "react";
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';

const PetProfileTable = props => {
    if(props.mobileNumber===''||props.mobileNumber===null)
    {
        return (
            <Table striped bordered variant="light">
              <tbody style={{ fontSize: 'large' }}>
                <tr>
                  <td>Name</td>
                  <td>{props.petName}</td>
                </tr>
                <tr>
                  <td>Type</td>
                  <td>{props.petType}</td>
                </tr>
                <tr>
                  <td>Breed</td>
                  <td>{props.petBreed}</td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td>{props.petAge}</td>
                </tr>
              </tbody>
            </Table>
          );
    }
    else
    {
  return (
    <Table striped bordered variant="light">
      <tbody style={{ fontSize: 'large' }}>
        <tr>
          <td>Name</td>
          <td>{props.petName}</td>
        </tr>
        <tr>
          <td>Type</td>
          <td>{props.petType}</td>
        </tr>
        <tr>
          <td>Breed</td>
          <td>{props.petBreed}</td>
        </tr>
        <tr>
          <td>Age</td>
          <td>{props.petAge}</td>
        </tr>
        <tr>
          <td>MobileNumber</td>
          <td>{props.mobileNumber}</td>
        </tr>
      </tbody>
    </Table>
  );
    }
};

export default PetProfileTable;