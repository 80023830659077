import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import AdultDogBanner from '../../../Resources/Consumer/AdultDogBanner.png';
import AdultCatBanner from '../../../Resources/Consumer/AdultCatBanner.png';
import PuppyBanner from '../../../Resources/Consumer/PuppyBanner.png';
import KittenBanner from '../../../Resources/Consumer/KittenBanner.png';
import './PetFoodRecommendationCard.css';
import {redirectionLinkGeneration} from '../../../Resources/Consumer/Utilities';
import Config from '../../Bot/Config'

const PetFoodRecommendationCard = (props) => 
{
  let banner=null;
  if (props.petType === 'Dog') 
  {
    if ((props.petAge).includes('Puppy')) 
    {
      banner = PuppyBanner;
    }
    else 
    {
      banner = AdultDogBanner;
    }
  }
  else 
  {
    if ((props.petAge).includes('Kitten')) 
    {
      banner = KittenBanner;
    }
    else 
    {
      banner=AdultCatBanner;
    }
  }
  
  const redirectionLink=redirectionLinkGeneration(props.retailerId,props.cookieValue,props.petType,props.petAge);
  const dogMythsLink=Config.state.dogMythsLink;
  const catMythsLink=Config.state.catMythsLink;
  const dogFoodAvoidLink=Config.state.dogFoodAvoidLink;
  const catFoodAvoidLink=Config.state.catFoodAvoidLink;
  return (
    <div>
      {
        props.petType === 'Dog'
          ? <Card bg='white' text='white' style={{ width: '100%' }}>
              <a className='lightbox' href='#img'>
                <img src={banner} />
              </a>
              <div className='lightbox-target' id='img'>
                <img src={banner} />
                <a className='lightbox-close' href='#'></a>
              </div>
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Button style={{ margin: '15px', backgroundColor: '#fdc101', borderColor: '#fdc101', fontSize: 'large', color: 'black' }} variant='primary' onClick={() => window.open(redirectionLink, '_blank')}>Click here to Purchase</Button>
                <Button style={{ margin: '15px', backgroundColor: '#fdc101', borderColor: '#fdc101', fontSize: 'large', color: 'black' }} variant='primary' onClick={() => window.open(dogMythsLink, '_blank')}>Myths and Facts about Pet food </Button>
                <Button style={{ margin: '15px', backgroundColor: '#fdc101', borderColor: '#fdc101', fontSize: 'large', color: 'black' }} variant='primary' onClick={() => window.open(dogFoodAvoidLink, '_blank')}>Human Foods to Avoid for your Pet </Button>
                {/* <Button style={{ margin: '15px', backgroundColor: '#fdc101', borderColor: '#fdc101', fontSize: 'large', color: 'black' }} variant='primary' onClick={() => props.actionProvider.handleExit()}>Exit </Button> */}
              </Card.Body>
            </Card>
          : <Card bg='white' text='white' style={{ width: '100%' }}>
              <a className='lightbox' href='#img'>
                <img src={banner} />
              </a>
              <div className='lightbox-target' id='img'>
                <img src={banner} />
                <a className='lightbox-close' href='#'></a>
              </div>
              <Card.Body style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Button style={{ margin: '15px', backgroundColor: '#b522b5', borderColor: '#b522b5', fontSize: 'large' }} variant='primary' onClick={() => window.open(redirectionLink, '_blank')}>Click here to Purchase</Button>
                <Button style={{ margin: '15px', backgroundColor: '#b522b5', borderColor: '#b522b5', fontSize: 'large' }} variant='primary' onClick={() => window.open(catMythsLink, '_blank')}>Myths and Facts about Pet food </Button>
                <Button style={{ margin: '15px', backgroundColor: '#b522b5', borderColor: '#b522b5', fontSize: 'large' }} variant='primary' onClick={() => window.open(catFoodAvoidLink, '_blank')}>Human Foods to Avoid for your Pet </Button>
                {/* <Button style={{ margin: '15px', backgroundColor: '#b522b5', borderColor: '#b522b5', fontSize: 'large' }} variant='primary' onClick={() => props.actionProvider.handleExit()}>Exit </Button> */}
              </Card.Body>
            </Card>
      }
    </div>
  );
};

export default PetFoodRecommendationCard;