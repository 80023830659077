import React from 'react';
import GeneralOptions from '../../GeneralOptions/GeneralOptions';

const PetParentTypeOptions = (props) => {
  const options = 
  [
    {
      name: 'First time User',
      handler: props.actionProvider.handlePetParentType,
      id: 1
    },
    {
      name: 'Returning User',
      handler: props.actionProvider.handlePetParentType,
      id: 2
    }
  ];
  return <GeneralOptions options={options}{...props} />;
};

export default PetParentTypeOptions;