import React from "react";
import GeneralOptions from '../../GeneralOptions/GeneralOptions';

const UpdatePetProfileOptions = (props) => 
{
  const options = 
  [
    {
      name: "PetName",
      handler: props.actionProvider.handleUpdatePetProfile,
      id: 2
    },
    {
      name: "PetType",
      handler: props.actionProvider.handleUpdatePetProfile,
      id: 3
    },
    {
      name: "PetBreed",
      handler: props.actionProvider.handleUpdatePetProfile,
      id: 4
    },
    {
      name: "PetAge",
      handler: props.actionProvider.handleUpdatePetProfile,
      id: 5
    },
    {
      name: "NO",
      handler: props.actionProvider.handleUpdatePetProfile,
      id: 6
    },

  ];
  return <GeneralOptions options={options} {...props} />;
}
export default UpdatePetProfileOptions;